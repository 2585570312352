<template>
  <div class="footer-logos">
    <div
      v-for="(logo, index) in logos"
      :key="index"
      class="logo"
    >
      <span
        v-if="logo?.image?.filename"
        class="logo--icon"
        :style="[{ maskImage: `url(${logo.image.filename})` }, { WebkitMaskImage: `url(${logo.image.filename})` }]"
        :alt="logo.image?.alt"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterLogos',
  props: {
    logos: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.footer-logos {
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: spacing('small');
  padding-left: spacing('medium');
  padding-right: spacing('medium');
  padding-bottom: spacing('medium');

  .logo {
    height: 2.3rem;
    width: 4rem;
    display: inline-block;

    .logo--icon {
      display: block;
      height: 100%;
      width: 100%;

      // Colorize SVG image
      mask-size: 100% 100%;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      mask-position: center;
      background-color: currentColor;
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .footer-logos {
    gap: spacing('xlarge');
    padding-top: 12rem;
    padding-bottom: 0;

    .logo {
      width: 6rem;
      height: 2.4rem;
    }
  }
}
</style>
